import React from "react";
import styled from "styled-components";
// Assets (Update these paths based on your project structure)
import consulting from "../../assets/icons/consulting.png";
import MonitorIcon from "../../assets/icons/monitor-icon.png";
import BrowserIcon from "../../assets/icons/browser-icon.png";
import recruitment from "../../assets/icons/recruitment.png";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "recruitment":
      getIcon = <img src={recruitment} alt="recruitment Icon" />;
      break;
    case "monitor":
      getIcon = <img src={MonitorIcon} alt="Monitor Icon" />;
      break;
    case "browser":
      getIcon = <img src={BrowserIcon} alt="Browser Icon" />;
      break;
    case "consulting":
      getIcon = <img src={consulting} alt="consulting Icon" />;
      break;
    default:
      getIcon = <img src={consulting} alt="Default Icon" />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  img {
    width: 48px; /* Adjust size as needed */
    height: 48px;
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">About Us</h1>
            <p className="font13">
              At XtremeDev, we are driven by a passion for delivering exceptional staffing solutions and cutting-edge IT development services.
              <br />
              As a growing startup, we believe in empowering businesses with the right talent and innovative technologies.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {/* First Row */}
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Who We Are!"
                text="We are a team of professionals committed to providing tailored solutions for staffing and IT development. With a deep understanding of industry demands, we bridge the gap between companies and skilled candidates while delivering robust technical solutions to drive business growth."
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="What We Do"
                text="At XtremeDev, we offer comprehensive solutions to meet your business needs:

                  We identify, source, and deliver top-tier talent, ensuring businesses get the right candidates under our payroll for a seamless hiring experience.

                  In addition, our expert team specializes in end-to-end IT development, building scalable and modern web and mobile solutions to address complex business challenges and drive innovation."
              />
            </div>
          </div>
          <div className="row textCenter">
            {/* Second Row */}
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Our Vision"
                text="To empower businesses through innovative technologies and exceptional talent solutions."
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Why Choose Us?"
                text="A highly dedicated team of experts. Proven expertise in staffing and IT development. A focus on delivering quality, efficiency, and innovation. Tailored solutions to meet your unique business needs."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Voices That Inspire Us</h1>
            <p className="font13">
              We are guided by powerful words that reflect our mission to connect talent and technology.
              <br />
              These insights inspire our journey and drive our commitment to excellence.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;

const Column = styled.div`
  flex: 0 0 50%; /* Each box takes 50% of the row */
  max-width: 50%;
  padding: 15px;

  @media (max-width: 768px) {
    flex: 0 0 100%; /* Each box takes 100% width on small screens */
    max-width: 100%;
  }
`;

import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Technology</h1>
            <p className="font13">
            At XtremeDev, we harness the power of advanced technologies to build scalable, secure, and future-ready solutions.
              <br />
              Our focus on innovation ensures businesses achieve unmatched efficiency and growth.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Web Technologies"
                text="Empowering Businesses with Cutting-Edge Web Solutions. We specialize in modern web technologies like HTML5, CSS3, JavaScript (ES6+), React.js, and Next.js to deliver responsive, interactive, and performance-optimized web applications tailored to business goals."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Scalable and Secure Cloud Infrastructure Solutions"
                text="Scalable and Secure Cloud Infrastructure Solutions.Our cloud services leverage platforms like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud to design reliable, scalable, and cost-effective cloud architectures with seamless migration and management."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Mobile Technologies"
                text="Seamless Mobile Experiences for Global Reach.We deliver intuitive and high-performance mobile applications using Flutter, React Native, Swift, and Kotlin, ensuring native-like functionality across iOS and Android platforms."
                
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Database Technologies"
                text="Optimized Data Management for Scalable Applications. Our expertise spans relational and non-relational databases such as MySQL, PostgreSQL, MongoDB, and Redis to design secure, fast, and highly scalable database solutions that meet enterprise needs."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="DevOps and CI/CD Automation"
                text="Accelerating Development with Automation and Efficiency. We implement robust DevOps practices with tools like Docker, Kubernetes, Jenkins, and GitLab CI/CD, streamlining development pipelines for faster, automated deployments and improved operational efficiency."
                
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Cybersecurity Solutions"
                text="Building Secure and Resilient Digital Solutions.Security is at the core of our development. We employ advanced security frameworks like Zero Trust Architecture, Data Encryption Standards, and Penetration Testing to safeguard businesses from threats and vulnerabilities."
            
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
             
            </div>
          </div>
        </div>
      </div>
      
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

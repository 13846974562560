import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Landing from "./screens/Landing";

export default function App() {
  return (
    <>
      <HelmetProvider>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
        
      <Landing />
      </HelmetProvider>
    </>
  );
}

